<template>
  <div>
    <vue-snotify></vue-snotify>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="ml-10 mt-10 mb-10">
          <v-card-title class="cyan darken-1">
            <v-row>
              <v-col cols="12" md="9">
                <span class="white--text">Administrar Equipos</span></v-col
              >
              <v-col cols="12" md="3">
                <div class="text-right mr-5">
                  <v-btn
                    small
                    color="white"
                    :to="{ name: 'create-equipments' }"
                  >
                    <v-icon small dark> mdi-plus-circle </v-icon> Agregar equipo
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="equipments"
            :search="search"
            :footer-props="{
              itemsPerPageText: 'Filas por página',
              itemsPerPageAllText: 'todos',
            }"
            no-data-text="No hay datos disponibles"
            no-results-text="No hay datos disponibles"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                :src="getUrlImage(item.image)"
                max-height="150"
                max-width="100"
              ></v-img>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon :to="'/actualizar-equipos/' + item.id">
                    <v-icon v-bind="attrs" color="primary" v-on="on">
                      mdi-pencil-box-multiple
                    </v-icon>
                  </v-btn>
                </template>
                <span>Modificar</span>
              </v-tooltip>
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                    <v-card-text>
                      <div class="text-h7 pa-6">
                        ¿Estás seguro que deseas eliminar el equipo?
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                      <v-btn
                        class="primary"
                        text
                        @click="
                          dialog.value = false;
                          deleteItem(item);
                        "
                        >Aceptar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Nombre",
        align: "start",

        value: "name",
      },
      { text: "Descripción", value: "description" },
      { text: "Precio", value: "price" },
      { text: "Precio anterior", value: "previous_price" },      
      { text: "Acciones", value: "actions" },
    ],
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "El tamaño de la imagen debe ser menor de 2 MB!",
    ],
    equipments: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      console.log("entra a buscar equipos");
      axios
        .get("/get-equipment")
        .then((response) => {
          this.equipments = response.data;
          console.log("imprime equipo");
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener equipo");
        });
    },
    getUrlImage(image) {
      console.log("imprime url");
      console.log(image);
      return "https://api.sipymex.cl/equipments/" + image;
    },
    deleteItem(item) {
      console.log("eliminando");
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/destroy-equipment", request)
        .then((response) => {
          console.log(response.data);
          this.displayNotification(
            "success",
            "Éxito",
            "Equipo eliminado correctamente "
          );
          setTimeout(() => this.initialize(), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el equipo"
          );
        });
    },
  },
};
</script>