var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-snotify'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"ml-10 mt-10 mb-10"},[_c('v-card-title',{staticClass:"cyan darken-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',{staticClass:"white--text"},[_vm._v("Administrar Equipos")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-right mr-5"},[_c('v-btn',{attrs:{"small":"","color":"white","to":{ name: 'create-equipments' }}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Agregar equipo ")],1)],1)])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.equipments,"search":_vm.search,"footer-props":{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          },"no-data-text":"No hay datos disponibles","no-results-text":"No hay datos disponibles"},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
          var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.getUrlImage(item.image),"max-height":"150","max-width":"100"}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","to":'/actualizar-equipos/' + item.id}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-box-multiple ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modificar")])]),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red darken-2"}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Mensaje")]),_c('v-card-text',[_c('div',{staticClass:"text-h7 pa-6"},[_vm._v(" ¿Estás seguro que deseas eliminar el equipo? ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"primary",attrs:{"text":""},on:{"click":function($event){dialog.value = false;
                        _vm.deleteItem(item);}}},[_vm._v("Aceptar")])],1)],1)]}}],null,true)})]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }